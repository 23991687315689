import GroupsPage from "../../types/pages/GroupsPage";

const groups: GroupsPage = {
  didGet: false,
  filter_value: "",

  groups: [],
  filtered_groups: [],
  selected_groups: [],
};

export default groups;
