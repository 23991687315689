import TimetablePage from "../../types/pages/TimetablePage";

const timetable: TimetablePage = {
  didGet: false,

  timetable: [],
  items: [],
  headers: [],
};

export default timetable;
