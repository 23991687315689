import CabinetsPage from "../../types/pages/CabinetsPage";

const cabinets: CabinetsPage = {
  didGet: false,
  filter_value: "",

  cabinets: [],
  filtered_cabinets: [],
  selected_cabinets: [],
};

export default cabinets;
