import EducatorsPage from "../../types/pages/EducatorsPage";

const educators: EducatorsPage = {
  didGet: false,
  filter_value: "",
  filtered_educators: [],
  selected_educators: [],
};

export default educators;
